import React from "react"
import styled from "styled-components"
import { rem, fluidRange, transparentize } from "polished"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdArrowBack } from "react-icons/md"
import rehypeReact from "rehype-react"
import loadable from "@loadable/component"

import { cardBorders } from "../utils/styles"
import { createNodePropGetter } from "../utils/misc"
import SEO from "../containers/seo"
import _Layout, { Main } from "../containers/layout"
import _HiddenHeading from "../components/hidden-heading"
import TeamMember from "../components/cards/team-member"
import _CaseStudy from "../components/cards/case-study"
import _Category from "../components/category/category"
import { List, ListItem } from "../components/lists/list"

export default function CaseStudies({ data }) {
  return (
    <Layout>
      <SEO
        title={data.caseStudy.seoTitle}
        description={data.caseStudy.seoDescription.seoDescription}
        creator={data.caseStudy.seoCreator}
        image={data.caseStudy.seoImage?.localFile?.childImageSharp?.fluid.src}
      />
      <Breadcrumbs>
        <List>
          <ListItem>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem>
            <Link to="/case-studies/">Case Studies</Link>
          </ListItem>
          <ListItem>{data.caseStudy.title}</ListItem>
        </List>
      </Breadcrumbs>
      <Header>
        <BackToCaseStudies />
        {data.caseStudy.client?.logo &&
          (data.caseStudy.client?.website?.length > 0 ? (
            <ClientLogoLink
              href={data.caseStudy.client.website}
              target="_blank"
              rel="noreferrer noopener"
              title={data.caseStudy.client?.name}
            >
              <ClientLogo
                fluid={
                  data.caseStudy.client.logo.localFile.childImageSharp.fluid
                }
              />
            </ClientLogoLink>
          ) : (
            <ClientLogo
              fluid={data.caseStudy.client.logo.localFile.childImageSharp.fluid}
            />
          ))}
        <Heading>{data.caseStudy.title}</Heading>
        <Date datetime={data.caseStudy.date}>
          {data.caseStudy.formattedDate}
        </Date>
        <Category
          category={{
            text: data.caseStudy.category.text,
            url: `/case-studies/category/${data.caseStudy.category.url}`,
          }}
          tags={data.caseStudy.tags.map(({ id, text, url }) => ({
            id,
            text,
            url: `/case-studies/tag/${url}`,
          }))}
        />
      </Header>
      <PosterImage
        fluid={data.caseStudy.poster.localFile.childImageSharp.fluid}
      />
      <Content>
        {renderAst(data.caseStudy.content.childMarkdownRemark.htmlAst)}
      </Content>
      <Footer>
        <HiddenHeading>Footer</HiddenHeading>
        {data.caseStudy.author && (
          <Section>
            <SectionTitle>About author</SectionTitle>
            <Author
              photo={
                <Img
                  fluid={
                    data.caseStudy.author.photo.localFile.childImageSharp.fluid
                  }
                />
              }
              name={data.caseStudy.author.name}
              nameProps={{ as: "h5" }}
              position={data.caseStudy.author.position}
              bio={data.caseStudy.author.biography.biography}
              socialLinks={data.caseStudy.author.socialLinks}
            />
          </Section>
        )}
        {data.caseStudies.nodes.length > 0 && (
          <Section as="aside">
            <SectionTitle>Read more</SectionTitle>
            <Cases>
              {data.caseStudies.nodes.map(
                ({
                  title,
                  thumbnail,
                  description: { description },
                  slug,
                  category,
                  tags,
                }) => (
                  <CaseStudy
                    key={slug}
                    image={
                      <Img fluid={thumbnail.localFile.childImageSharp.fluid} />
                    }
                    title={title}
                    description={description}
                    url={`/case-study/${slug}`}
                    category={{
                      text: category.text,
                      url: `/case-studies/category/${category.url}`,
                    }}
                    tags={tags.map(({ id, text, url }) => ({
                      id,
                      text,
                      url: `/case-studies/tag/${url}`,
                    }))}
                  />
                ),
              )}
            </Cases>
          </Section>
        )}
      </Footer>
    </Layout>
  )
}

export const SyntaxHighlighter = loadable(() =>
  import("react-syntax-highlighter"),
)

const Section = styled.section`
  grid-column: full;
  display: inherit;
  grid-template-columns: inherit;
  column-gap: inherit;
`
const _Content = styled.div`
  grid-column: main;

  @media screen and (min-width: ${rem("1200px")}) {
    grid-column: 4 / span 8;
  }
`
const _Heading = styled.h1`
  margin: 0.67em 0;
`
const H1 = styled(_Heading).attrs({ as: "h3" })`
  font-size: ${rem("36px")};
`
const H2 = styled(_Heading).attrs({ as: "h4" })`
  font-size: ${rem("32px")};
`
const H3 = styled(_Heading).attrs({ as: "h5" })`
  font-size: ${rem("28px")};
`
const H4 = styled(_Heading).attrs({ as: "h6" })`
  font-size: ${rem("24px")};
`
const H5 = styled(_Heading).attrs({ as: "h6" })`
  font-size: ${rem("20px")};
`
const H6 = styled(_Heading).attrs({ as: "h6" })`
  font-size: ${rem("18px")};
`
const Content = styled(_Content)`
  font-size: ${rem("18px")};
  font-weight: 300;
  line-height: 1.89;
  word-wrap: break-word;

  b,
  strong {
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  pre {
    > pre {
      max-height: ${rem("698px")};
    }
  }

  table {
    --color: #dbdce4;

    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      background-color: var(--color);
    }

    &,
    td {
      border: 1px solid var(--color);
      white-space: nowrap;
    }

    th,
    td {
      padding: ${rem("5px")};
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
const Breadcrumbs = styled.div`
  grid-column: full;
  justify-self: center;
  width: 100%;
  padding: ${rem("10px")} 0;
  border: 1px solid ${props => transparentize(0.8, props.theme.color.dark)};
  border-left-width: 0;
  border-right-width: 0;
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.theme.color.text};

  ${List} {
    display: flex;
    flex-direction: row;
    justify-items: start;
  }

  ${ListItem} {
    width: auto;

    &:not(:last-of-type)::after {
      content: "/";

      padding: 0 ${rem("10px")};

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  & {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
  }

  ${List} {
    grid-column: main;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
const Cases = styled(_Content).attrs({ as: "div" })`
  display: flex;
  flex-wrap: wrap;
`
const CaseStudy = styled(_CaseStudy)`
  margin: ${rem("14px")} 0;

  @media screen and (min-width: ${rem("1200px")}) {
    flex-basis: 30%;
    margin: ${rem("14px")};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:hover {
    transform: none;
  }
`
const ClientLogo = styled(_Content).attrs({
  as: Img,
  imgStyle: { objectFit: "contain" },
})`
  max-width: ${rem("200px")};
  user-select: none;
`
const ClientLogoLink = styled(_Content).attrs({ as: "a" })``
const Category = styled(_Content).attrs({ as: _Category })``
const HiddenHeading = props => <_HiddenHeading as="h3" {...props} />
const Header = styled(Section).attrs({ as: "header" })`
  row-gap: ${rem("36px")};
`
const Footer = styled(Section).attrs({ as: "footer" })`
  row-gap: ${rem("85px")};
`
const Heading = styled(_Content).attrs({ as: "h2" })`
  line-height: 1.3;
  font-weight: 500;
  margin: 0;

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "24px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
const Date = styled(_Content).attrs({ as: "time" })`
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => transparentize(0.7, props.theme.color.text)};
`
const PosterImage = styled(Img)`
  ${cardBorders(20)}

  height: 0;
  padding-top: ${`${(64 / 209) * 100}%`};
  grid-column: main;

  img {
    object-fit: cover;
    user-select: none;
  }

  @media screen and (min-width: ${rem("1200px")}) {
    grid-column: 3 / span 10;
  }
`
const SectionTitle = styled(_Content).attrs(props => ({ as: "h4", ...props }))`
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
`
const Author = styled(_Content).attrs({ as: TeamMember })``
const Layout = styled(_Layout)`
  ${Main} {
    padding-top: ${rem("30px")};

    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "65px",
        toSize: "115px",
      },
      "400px",
      "1200px",
    )}
  }
`
const BackToCaseStudies = styled(SectionTitle).attrs({
  as: Link,
  to: "/case-studies/",
  children: (
    <>
      <MdArrowBack /> <span>Case Studies</span>
    </>
  ),
})`
  grid-column: full;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  > svg {
    color: ${props => props.theme.color.iconPrimary};

    & + span {
      margin-left: ${rem("5px")};
    }
  }

  @media screen and (min-width: ${rem("1200px")}) {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
    grid-column: full;

    > svg {
      grid-column: 3;
      justify-self: end;

      & + span {
        margin-left: 0;
      }
    }

    > span {
      grid-column: 4 / span 8;
      justify-self: start;
    }
  }
`
const Figure = styled.figure`
  margin: 0;

  figcaption {
    font-size: ${rem("16px")};
    font-weight: 500;
    color: ${props => transparentize(0.6, props.theme.color.text)};
  }
`

export const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    code({ className, children }) {
      const [, language] = className?.split("language-") ?? []

      if (children?.[0]?.includes("\n")) {
        return (
          <SyntaxHighlighter
            showLineNumbers
            showInlineLineNumbers
            language={language}
            children={children}
          />
        )
      }

      return <code {...{ className, children }} />
    },
    a(props) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      const imgLink = <a {...props} />

      if (props?.className !== "gatsby-resp-image-link") {
        return imgLink
      }

      const title = _getImgTitle(props)

      if (!title) {
        return imgLink
      }

      return (
        <Figure>
          {imgLink}
          <figcaption>{title}</figcaption>
        </Figure>
      )
    },
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
  },
}).Compiler

const _getImgTitle = createNodePropGetter("img")("title")

export const query = graphql`
  query($slug: String) {
    caseStudy: contentfulCaseStudy(slug: { eq: $slug }) {
      title
      date: createdAt
      formattedDate: createdAt(formatString: "MMM Do, YYYY")
      seoTitle
      seoDescription {
        seoDescription
      }
      seoCreator
      seoImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2048) {
              src
            }
          }
        }
      }
      category {
        text
        url
      }
      tags {
        id
        text
        url
      }
      client {
        name
        website
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      poster {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1045, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content {
        content
        childMarkdownRemark {
          htmlAst
        }
      }
      author {
        name
        position
        biography {
          biography
        }
        socialLinks: childrenContentfulAuthorSocialLinkJsonNode {
          id
          type
          url
        }
        photo {
          localFile {
            childImageSharp {
              fluid(maxHeight: 136, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    caseStudies: allContentfulCaseStudy(
      limit: 3
      filter: { slug: { ne: $slug } }
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
    ) {
      nodes {
        title
        category {
          text
          url
        }
        tags {
          id
          text
          url
        }
        description {
          description
        }
        slug
        featured
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 461, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
