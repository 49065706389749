import React from "react"
import styled from "styled-components"
import { rem, fluidRange, transparentize, ellipsis } from "polished"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdArrowBack } from "react-icons/md"
import { FaRegClock, FaFileDownload, FaSignInAlt } from "react-icons/fa"

import { cardBorders } from "../utils/styles"
import { renderAst } from "./case-study"
import SEO from "../containers/seo"
import _Layout, { Main } from "../containers/layout"
import _HiddenHeading from "../components/hidden-heading"
import TeamMember from "../components/cards/team-member"
import _CaseStudy from "../components/cards/case-study"
import _Category from "../components/category/category"
import { List, ListItem } from "../components/lists/list"
import { Image } from "../components/cards/img-card"
import Button from "../components/buttons/button"

export default function WorkshopPage({ data }) {
  const info = (
    <Info>
      {data.workshop?.duration && (
        <Duration title="Time duration">
          <FaRegClock />
          &nbsp;<HiddenText>Time duration:</HiddenText>&nbsp;
          <DurationText title={data.workshop.duration}>
            {data.workshop.duration}
          </DurationText>
        </Duration>
      )}
      {data.workshop?.agenda?.localFile?.publicURL && (
        <Button
          as="a"
          target="_blank"
          href={data.workshop.agenda.localFile.publicURL}
        >
          <FaFileDownload />
          &nbsp;agenda
        </Button>
      )}
      <ContactButton />
    </Info>
  )

  return (
    <Layout>
      <SEO
        title={data.workshop.seoTitle}
        description={data.workshop.seoDescription.seoDescription}
        creator={data.workshop.seoCreator}
        image={data.workshop.seoImage?.localFile?.childImageSharp?.fluid.src}
      />
      <Breadcrumbs>
        <List>
          <ListItem>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem>
            <Link to="/workshops/">Workshop</Link>
          </ListItem>
          <ListItem>{data.workshop.title}</ListItem>
        </List>
      </Breadcrumbs>
      <Header>
        <BackToWorkshops />
        {data.workshop.client?.logo &&
          (data.workshop.client?.website?.length > 0 ? (
            <ClientLogoLink
              href={data.workshop.client.website}
              target="_blank"
              rel="noreferrer noopener"
              title={data.workshop.client?.name}
            >
              <ClientLogo
                fluid={
                  data.workshop.client.logo.localFile.childImageSharp.fluid
                }
              />
            </ClientLogoLink>
          ) : (
            <ClientLogo
              fluid={data.workshop.client.logo.localFile.childImageSharp.fluid}
            />
          ))}
        <Heading>{data.workshop.title}</Heading>
        <Category
          category={{
            text: data.workshop.category.text,
            url: `/workshops/category/${data.workshop.category.url}`,
          }}
          tags={data.workshop.tags.map(({ id, text, url }) => ({
            id,
            text,
            url: `/workshops/tag/${url}`,
          }))}
        />
        {info}
      </Header>
      <PosterImage
        fluid={data.workshop.poster?.localFile?.childImageSharp?.fluid}
      />
      <Content>
        {renderAst(data.workshop.content.childMarkdownRemark.htmlAst)}
      </Content>
      <Footer>
        <HiddenHeading>Footer</HiddenHeading>
        {info}
        {data.workshop.author && (
          <Section>
            <SectionTitle>About trainer</SectionTitle>
            <Author
              photo={
                <Img
                  fluid={
                    data.workshop.author.photo.localFile.childImageSharp.fluid
                  }
                />
              }
              name={data.workshop.author.name}
              nameProps={{ as: "h5" }}
              position={data.workshop.author.position}
              bio={data.workshop.author.biography.biography}
              socialLinks={data.workshop.author.socialLinks}
            />
          </Section>
        )}
        {data.workshops.nodes.length > 0 && (
          <Section as="aside">
            <SectionTitle>Learn more</SectionTitle>
            <WorkshopList>
              {data.workshops.nodes.map(
                ({ title, thumbnail, slug, category, tags }) => (
                  <Workshop
                    key={slug}
                    image={
                      <Img fluid={thumbnail.localFile.childImageSharp.fluid} />
                    }
                    title={title}
                    url={`/workshop/${slug}`}
                    category={{
                      text: category.text,
                      url: `/workshops/category/${category.url}`,
                    }}
                    tags={tags.map(({ id, text, url }) => ({
                      id,
                      text,
                      url: `/workshops/tag/${url}`,
                    }))}
                  />
                ),
              )}
            </WorkshopList>
          </Section>
        )}
      </Footer>
    </Layout>
  )
}

const Section = styled.section`
  grid-column: full;
  display: inherit;
  grid-template-columns: inherit;
  column-gap: inherit;
`
const _Content = styled.div`
  grid-column: main;

  @media screen and (min-width: ${rem("1200px")}) {
    grid-column: 4 / span 8;
  }
`

const Content = styled(_Content)`
  font-size: ${rem("18px")};
  font-weight: 300;
  line-height: 1.89;
  word-wrap: break-word;
`
const Breadcrumbs = styled.div`
  grid-column: full;
  justify-self: center;
  width: 100%;
  padding: ${rem("10px")} 0;
  border: 1px solid ${props => transparentize(0.8, props.theme.color.dark)};
  border-left-width: 0;
  border-right-width: 0;
  font-size: ${rem("16px")};
  font-weight: 500;
  color: ${props => props.theme.color.text};

  ${List} {
    display: flex;
    flex-direction: row;
    justify-items: start;
  }

  ${ListItem} {
    width: auto;

    &:not(:last-of-type)::after {
      content: "/";

      padding: 0 ${rem("10px")};

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  & {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
  }

  ${List} {
    grid-column: main;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
const WorkshopList = styled(_Content).attrs({ as: "div" })`
  display: flex;
  flex-wrap: wrap;
`
const Workshop = styled(_CaseStudy)`
  margin: ${rem("14px")} 0;

  @media screen and (min-width: ${rem("1200px")}) {
    flex-basis: 48%;
    margin: ${rem("14px")};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:hover {
    transform: none;
  }

  ${Image} {
    padding-top: 56.25%;
  }
`
const ClientLogo = styled(_Content).attrs({
  as: Img,
  imgStyle: { objectFit: "contain" },
})`
  max-width: ${rem("200px")};
  user-select: none;
`
const ClientLogoLink = styled(_Content).attrs({ as: "a" })``
const Category = styled(_Content).attrs({ as: _Category })``
const HiddenHeading = props => <_HiddenHeading as="h3" {...props} />
const Header = styled(Section).attrs({ as: "header" })`
  row-gap: ${rem("36px")};
`
const Footer = styled(Section).attrs({ as: "footer" })`
  row-gap: ${rem("85px")};
`
const Heading = styled(_Content).attrs({ as: "h2" })`
  line-height: 1.3;
  font-weight: 500;
  margin: 0;

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "24px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
const PosterImage = styled(Img)`
  ${cardBorders(20)}

  height: 0;
  padding-top: ${`${(64 / 209) * 100}%`};
  grid-column: main;

  img {
    object-fit: cover;
    user-select: none;
  }

  @media screen and (min-width: ${rem("1200px")}) {
    grid-column: 3 / span 10;
  }
`
const SectionTitle = styled(_Content).attrs(props => ({ as: "h4", ...props }))`
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
`
const Author = styled(_Content).attrs({ as: TeamMember })``
const Layout = styled(_Layout)`
  ${Main} {
    padding-top: ${rem("30px")};

    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "65px",
        toSize: "115px",
      },
      "400px",
      "1200px",
    )}
  }
`
const BackToWorkshops = styled(SectionTitle).attrs({
  as: Link,
  to: "/workshops",
  children: (
    <>
      <MdArrowBack /> <span>Workshops</span>
    </>
  ),
})`
  grid-column: full;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  > svg {
    color: ${props => props.theme.color.iconPrimary};

    & + span {
      margin-left: ${rem("5px")};
    }
  }

  @media screen and (min-width: ${rem("1200px")}) {
    display: inherit;
    grid-template-columns: inherit;
    column-gap: inherit;
    grid-column: full;

    > svg {
      grid-column: 3;
      justify-self: end;

      & + span {
        margin-left: 0;
      }
    }

    > span {
      grid-column: 4 / span 8;
      justify-self: start;
    }
  }
`
const ContactButton = styled(Button).attrs({
  children: (
    <>
      <FaSignInAlt />
      &nbsp;sign up
    </>
  ),
  as: Link,
  to: "#contact",
})``
const HiddenText = styled(_HiddenHeading).attrs({ as: "span" })``
const DurationText = styled.span`
  @media screen and (min-width: ${rem("900px")}) {
    ${ellipsis(rem("390px"))}
  }
`
const Duration = styled(_Content)`
  font-size: ${rem("24px")};
  font-weight: 500;
  color: ${props => transparentize(0.48, props.theme.color.text)};
  display: flex;
  align-items: center;

  > svg {
    color: ${props => props.theme.color.iconPrimary};
    flex-shrink: 0;
  }
`
const Info = styled(_Content).attrs({ as: "section" })`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: ${rem("900px")} !important;

  ${Button} {
    display: inline-flex;
    align-items: center;
    margin: ${rem("10px")};
    min-width: ${rem("150px")};
  }

  ${Duration} {
    margin-bottom: ${rem("20px")};
  }

  @media screen and (min-width: ${rem("900px")}) {
    flex-direction: row;

    ${Button} {
      margin: 0 ${rem("5px")};

      &:last-of-type {
        margin-right: 0;
      }
    }

    ${Duration} {
      margin-bottom: 0;
      margin-right: auto;
    }
  }
`

export const query = graphql`
  query($slug: String) {
    workshop: contentfulWorkshop(slug: { eq: $slug }) {
      title
      seoTitle
      seoDescription {
        seoDescription
      }
      seoCreator
      seoImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2048) {
              src
            }
          }
        }
      }
      category {
        text
        url
      }
      tags {
        id
        text
        url
      }
      duration
      description {
        description
      }
      agenda {
        localFile {
          publicURL
        }
      }
      client {
        name
        website
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      poster {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1045, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content {
        childMarkdownRemark {
          htmlAst
        }
      }
      author {
        name
        position
        biography {
          biography
        }
        socialLinks: childrenContentfulAuthorSocialLinkJsonNode {
          id
          type
          url
        }
        photo {
          localFile {
            childImageSharp {
              fluid(maxHeight: 136, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    workshops: allContentfulWorkshop(
      limit: 2
      filter: { slug: { ne: $slug } }
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
    ) {
      nodes {
        title
        category {
          text
          url
        }
        tags {
          id
          text
          url
        }
        slug
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 790, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
