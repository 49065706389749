import { Type } from "../components/links/case-studies-filters"

export const createNodePropGetter = type => prop =>
  function _getNodeProp(obj) {
    if (obj?.type === type && obj?.props?.[prop]?.trim?.()?.length > 0) {
      return obj?.props?.[prop]
    }

    if (Array.isArray(obj)) {
      return obj.map(o => _getNodeProp(o, prop)).filter(Boolean)?.[0]
    }

    const children = obj?.children ?? obj?.props?.children

    if (Array.isArray(children)) {
      return _getNodeProp(children, prop)
    }
  }

export function createFilters(type, data) {
  let texts, slugs

  if (type === Type.CATEGORY) {
    ;({
      categories: { distinct: texts },
      categorySlugs: { distinct: slugs },
    } = data)
  } else if (type === Type.TAG) {
    ;({
      tags: { distinct: texts },
      tagSlugs: { distinct: slugs },
    } = data)
  } else {
    return []
  }

  return slugs.reduce((acc, slug, index) => {
    acc.push({ slug, text: texts[index] })

    return acc
  }, [])
}
