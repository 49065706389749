import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  Heading,
  List,
  ListItem,
  Link,
  Text,
} from "./case-studies-filters.style"

export const Type = {
  CATEGORY: "category",
  TAG: "tag",
}

CaseStudiesFilters.propTypes = {
  type: PropTypes.oneOf(Object.values(Type)).isRequired,
  allText: PropTypes.string,
  path: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      id: PropTypes.string,
    }).isRequired,
  ),
  headingProps: PropTypes.object,
}

export default function CaseStudiesFilters({
  type,
  path = `/case-studies`,
  allText = "All Case Studies",
  filters = [],
  headingProps = {},
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Heading {...headingProps} />
      <List>
        <ListItem>
          <Link to={path} activeClassName="is-active">
            <Text>{allText}</Text>
          </Link>
        </ListItem>
        {filters.map(({ id, text, slug }) => (
          <ListItem key={id ?? slug}>
            <Link to={`${path}/${type}/${slug}`} activeClassName="is-active">
              <Text>{text}</Text>
            </Link>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  )
}
