import styled from "styled-components"
import { rem } from "polished"
import { Link as _Link } from "gatsby"

import { List as _List, ListItem as _ListItem } from "../lists/list"
import HiddenHeading from "../hidden-heading"

export const Wrapper = styled.nav``
export const Heading = styled(HiddenHeading).attrs(props => ({
  children: props.children ?? "Case Studies Navigation",
}))``
export const List = styled(_List)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 10px;
`
export const ListItem = styled(_ListItem)`
  display: inline-flex;
  width: auto !important;
  padding: 0 ${rem("40px")};
`
export const Link = styled(_Link)`
  display: inherit;
  align-items: inherit;
  color: inherit;
  text-decoration: inherit;

  &.is-active {
    color: ${props => props.theme.color.primary};
  }
`
export const Img = styled.img``
export const Text = styled.span`
  white-space: nowrap;

  ${Img} + & {
    margin-left: ${rem("15px")};
  }
`
